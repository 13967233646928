import React from 'react';
import Helmet from 'react-helmet';
import PropTypes from 'prop-types';
import {Header} from 'components';
import {Layout, Container} from 'layouts';
import Grid from '@material-ui/core/Grid';
import styled from "@emotion/styled";

import EmailIcon from '@material-ui/icons/MailOutline';
import GithubIcon from '@material-ui/icons/GitHub';
import RoomIcon from '@material-ui/icons/Room';
import OrcidIcon from '../../public/icons/ORCID.png';

import Garcia from '../../public/people/egarcia.jpg';
import Hirsh from '../../public/people/lhirsh.jpeg';
import Tunque from '../../public/people/rtunque.jpg';
import Bezerra from '../../public/people/mbezerra.png';

import Parisi from '../../public/people/gparisi.png';
import Fornasari from '../../public/people/sfornasari.jpg';
import Palopoli from '../../public/people/npalopoli.jpg';
import Buitron from '../../public/people/mbuitron.jpg';

const Justified = styled.p`
    text-align: justify; 
`;

const Person = styled.img`
    height: auto;
    width: auto;
`;

const Link = styled.a`
    padding: 2px 5px;
    font-size: 1em;
`;

const List = styled.li`
    margin-bottom: 0;
    display: flex;
    flex-direction: row;
`;


const About = center => (
        <Layout>
            <Helmet title={'Bioinformática | About'}/>
            <Header title="About">Here's to know us more!</Header>
            <Container center={center}>
                <Grid container spacing={3} wrap={"wrap"}>
                    <Grid item xs={12}>
                        Bioinformatica.org is created and maintained in a joint effort by the Structural Bioinformatics
                        Group at
                        Universidad Nacional de Quilmes in Buenos Aires, Argentina (SBG-UNQ) and the Group of Artificial
                        Intelligence at Pontificia Universidad Católica de Perú in Lima, Perú (IA-PUCP).
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                        <h2>SBG-UNQ</h2>
                        <Justified>The<Link href="http://ufq.unq.edu.ar/sbg/" target="blank_">Structural Bioinformatics Group</Link>is 
                        focused in advancing knowledge about the structure,
                            dynamics, evolution and function of biological macromolecules by developing and applying
                            computational tools.</Justified>
                        <h3>Team</h3>
                        <Grid container>
                            <Grid container>
                                <Grid item xs={12} sm={3} md={3}>
                                    <Person src={Parisi} alt=""/>
                                </Grid>
                                <Grid item xs={12} sm={9} md={9}>
                                    <div style={{textAlign: 'justify'}}>
                                        <List>
                                            <span style={{fontWeight: 'bold'}}>Gustavo Daniel Parisi</span>
                                        </List>
                                        <List>
                                            <span>Principal Researcher - UNQ & CONICET</span>
                                        </List>
                                        <List>
                                            <EmailIcon/>
                                            <Link href="emailto:gustavo@unq.edu.ar"
                                                  target="blank_">gustavo@unq.edu.ar</Link>
                                        </List>
                                        <List>
                                            <img src={OrcidIcon} alt=""/>
                                            <Link href="https://orcid.org/0000-0001-7444-1624" target="blank_">
                                                ORCID: 0000-0001-7444-1624</Link>
                                        </List>
                                    </div>
                                </Grid>
                            </Grid>
                            <br/>
                            <Grid container>
                                <Grid item xs={12} sm={3} md={3}>
                                    <div><Person src={Fornasari} alt=""/></div>
                                </Grid>
                                <Grid item xs={12} sm={9} md={9}>
                                    <div style={{textAlign: 'justify'}}>
                                        <List>
                                            <span style={{fontWeight: 'bold'}}>Maria Silvina Fornasari</span>
                                        </List>
                                        <List>
                                            <span>Associated Professor - UNQ & CONICET</span>
                                        </List>
                                        <List>
                                            <EmailIcon/>
                                            <Link href="emailto:silvina333@gmail.com"
                                                  target="blank_">silvina333@gmail.com</Link>
                                        </List>
                                        <List>
                                            <img src={OrcidIcon} alt=""/>
                                            <Link href="https://orcid.org/0000-0003-2081-0222" target="blank_">
                                                ORCID: 0000-0003-2081-0222</Link>
                                        </List>
                                    </div>
                                </Grid>
                            </Grid>
                            <br/>
                            <Grid container>
                                <Grid item xs={12} sm={3} md={3}>
                                    <div><Person src={Palopoli} alt="" style={{width: 'auto', height: 'auto'}}/></div>
                                </Grid>
                                <Grid item xs={12} sm={9} md={9}>
                                    <div style={{textAlign: 'justify'}}>
                                        <List>
                                            <span style={{fontWeight: 'bold'}}>Nicolas Palopoli</span>
                                        </List>
                                        <List>
                                            <span>Adjunct Researcher - UNQ & CONICET</span>
                                        </List>
                                        <List>
                                            <EmailIcon/>
                                            <Link href="mailto:npalopoli@unq.edu.ar"
                                                  target="blank_">npalopoli@unq.edu.ar</Link>
                                        </List>
                                        <List>
                                            <GithubIcon/>
                                            <Link href="https://github.com/NPalopoli" target="blank_">NPalopoli</Link>
                                        </List>
                                        <List>
                                            <img src={OrcidIcon} alt=""/>
                                            <Link href="https://orcid.org/0000-0001-7925-6436" target="blank_">
                                                ORCID: 0000-0001-7925-6436</Link>
                                        </List>
                                    </div>
                                </Grid>
                            </Grid>
                            <br/>
                            <Grid container>
                                <Grid item xs={12} sm={3} md={3}>
                                    <div><Person src={Buitron} alt="" style={{width: 'auto', height: 'auto'}}/></div>
                                </Grid>
                                <Grid item xs={12} sm={9} md={9}>
                                    <div style={{textAlign: 'justify'}}>
                                        <List>
                                            <span style={{fontWeight: 'bold'}}>Martín González Buitrón</span>
                                        </List>
                                        <List>
                                            <span>PhD Student - UNQ, Argentina</span>
                                        </List>
                                        <List>
                                            <EmailIcon/>
                                            <Link href="mailto:martingonzalezbuitron@gmail.com"
                                                  target="blank_">martingonzalezbuitron@gmail.com</Link>
                                        </List>
                                        <List>
                                            <GithubIcon/>
                                            <Link href="https://github.com/tinchogb" target="blank_">tinchogb</Link>
                                        </List>
                                        <List>
                                            <img src={OrcidIcon} alt=""/>
                                            <Link href="https://orcid.org/0000-0003-1123-9853" target="blank_">
                                                ORCID: 0000-0003-1123-9853</Link>
                                        </List>
                                    </div>
                                </Grid>
                            </Grid>
                            <Grid container>
                                <Grid item xs={12} sm={12} md={12}>
                                    <div>
                                        <iframe title="Location"
                                                style={{width: '100%', height: '450px'}}
                                                frameBorder="0"
                                                src={`https://www.google.com/maps/embed/v1/place?key=AIzaSyDaMocA4Fuc9FbzvgcYuYwqfg_43q1U2zE&q=UNIVERSIDAD+NACIONAL+DE+QUILMES`}
                                                allowFullScreen/>
                                    </div>
                                    <RoomIcon style={{color: '#2bbbad'}}/>
                                    <h4>Structural Bioinformatics Group</h4>
                                    <h4>National University of Quilmes</h4>
                                    <h4>Roque Sáenz Peña 352, Bernal, B1876BXD - Bs. As - Argentina</h4>
                                    <h4>
                                        Website:{' '}
                                        <a href="http://ufq.unq.edu.ar/sbg/"
                                           target="blank_">
                                           http://ufq.unq.edu.ar/sbg/
                                        </a>
                                    </h4>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                        <h2>IA-PUCP</h2>
                        <Justified>The<Link href="http://ia.inf.pucp.edu.pe/" target="blank_">Artificial Intelligence Group</Link>is 
                            the most important Peruvian center in Artificial
                            Intelligence and one of the most groundbreaking research groups at PUCP.</Justified>
                        <h3>Team</h3>
                        <Grid container>
                            <Grid container>
                                <Grid item xs={12} sm={3} md={3}>
                                    <Person src={Hirsh} alt=""/>
                                </Grid>
                                <Grid item xs={12} sm={9} md={9}>
                                    <div style={{textAlign: 'justify'}}>
                                        <List>
                                            <span style={{fontWeight: 'bold'}}>Layla Hirsh</span>
                                        </List>
                                        <List>
                                            <span>Principal Researcher & AI-PUCP Leader</span>
                                        </List>
                                        <List>
                                            <EmailIcon/>
                                            <Link href="emailto:lhirsh@pucp.edu.pe"
                                                  target="blank_">lhirsh@pucp.edu.pe</Link>
                                        </List>
                                        <List>
                                            <img src={OrcidIcon} alt=""/>
                                            <Link href="https://orcid.org/0000-0002-8215-6716" target="blank_">
                                                ORCID: 0000-0002-8215-6716</Link>
                                        </List>
                                    </div>
                                </Grid>
                            </Grid>
                            <br/>
                            <Grid container>
                                <Grid item xs={12} sm={3} md={3}>
                                    <div><Person src={Garcia} alt=""/></div>
                                </Grid>
                                <Grid item xs={12} sm={9} md={9}>
                                    <div style={{textAlign: 'justify'}}>
                                        <List>
                                            <span style={{fontWeight: 'bold'}}>Emilio García Ríos</span>
                                        </List>
                                        <List>
                                            <span>Researcher</span>
                                        </List>
                                        <List>
                                            <EmailIcon/>
                                            <Link href="emailto:emilio.garcia@pucp.edu.pe"
                                                  target="blank_"> emilio.garcia@pucp.edu.pe</Link>
                                        </List>
                                        <List>
                                            <GithubIcon/>
                                            <Link href="https://github.com/gariem" target="blank_">gariem</Link>
                                        </List>
                                        <List>
                                            <img src={OrcidIcon} alt=""/>
                                            <Link href="https://orcid.org/0000-0002-0699-4769" target="blank_">
                                                ORCID: 0000-0002-0699-4769</Link>
                                        </List>
                                    </div>
                                </Grid>
                            </Grid>
                            <br/>
                            <Grid container>
                                <Grid item xs={12} sm={3} md={3}>
                                    <div><Person src={Bezerra} alt="" style={{width: '6rem', height: 'auto'}}/></div>
                                </Grid>
                                <Grid item xs={12} sm={9} md={9}>
                                    <div style={{textAlign: 'justify'}}>
                                        <List>
                                            <span style={{fontWeight: 'bold'}}>Manuel A. Bezerra Brandao Corrales</span>
                                        </List>
                                        <List>
                                            <span>Teaching Assistant - PUCP, Perú</span>
                                        </List>
                                        <List>
                                            <EmailIcon/>
                                            <Link href="mailto:mbezerrabrandao@pucp.edu.pe"
                                                  target="blank_">mbezerrabrandao@pucp.edu.pe</Link>
                                        </List>
                                        <List>
                                            <div style={{display: 'flex', alignItems: 'center'}}>
                                                <svg width="25" height="20" class="tanuki-logo" viewBox="0 0 36 36">
                                                    <path class="tanuki-shape tanuki-left-ear" fill="#e24329" d="M2 14l9.38 9v-9l-4-12.28c-.205-.632-1.176-.632-1.38 0z"></path>
                                                    <path class="tanuki-shape tanuki-right-ear" fill="#e24329" d="M34 14l-9.38 9v-9l4-12.28c.205-.632 1.176-.632 1.38 0z"></path>
                                                    <path class="tanuki-shape tanuki-nose" fill="#e24329" d="M18,34.38 3,14 33,14 Z"></path>
                                                    <path class="tanuki-shape tanuki-left-eye" fill="#fc6d26" d="M18,34.38 11.38,14 2,14 6,25Z"></path>
                                                    <path class="tanuki-shape tanuki-right-eye" fill="#fc6d26" d="M18,34.38 24.62,14 34,14 30,25Z"></path>
                                                    <path class="tanuki-shape tanuki-left-cheek" fill="#fca326" d="M2 14L.1 20.16c-.18.565 0 1.2.5 1.56l17.42 12.66z"></path>
                                                    <path class="tanuki-shape tanuki-right-cheek" fill="#fca326" d="M34 14l1.9 6.16c.18.565 0 1.2-.5 1.56L18 34.38z"></path>
                                                </svg>
                                                <Link href="https://gitlab.com/mbezerrabrandao" target="blank_">mbezerrabrandao</Link>
                                            </div>
                                        </List>
                                        <List>
                                            <img src={OrcidIcon} alt=""/>
                                            <Link href="https://orcid.org/0000-0001-6334-161X" target="blank_">
                                                ORCID: 0000-0001-6334-161X</Link>
                                        </List>
                                    </div>
                                </Grid>
                            </Grid>
                            <br />
                            <Grid container>
                                <Grid item xs={12} sm={3} md={3}>
                                    <div><Person src={Tunque} alt="" style={{width: 'auto', height: 'auto'}}/></div>
                                </Grid>
                                <Grid item xs={12} sm={9} md={9}>
                                    <div style={{textAlign: 'justify'}}>
                                        <List>
                                            <span style={{fontWeight: 'bold'}}>Ronaldo Romario Tunque Cahui</span>
                                        </List>
                                        <List>
                                            <span>Teaching Assistant - PUCP, Perú</span>
                                        </List>
                                        <List>
                                            <EmailIcon/>
                                            <Link href="mailto:ronaldo.tunque@pucp.edu.pe"
                                                  target="blank_">ronaldo.tunque@pucp.edu.pe</Link>
                                        </List>
                                        <List>
                                            <GithubIcon/>
                                            <Link href="https://github.com/iKurito" target="blank_">iKurito</Link>
                                        </List>
                                        <List>
                                            <img src={OrcidIcon} alt=""/>
                                            <Link href="https://orcid.org/0000-0002-1010-1222" target="blank_">
                                                ORCID: 0000-0002-1010-1222</Link>
                                        </List>
                                    </div>
                                </Grid>
                            </Grid>
                            <br/>
                            <Grid container>
                                <Grid item xs={12} sm={12} md={12}>
                                    <div>
                                        <iframe title="Location"
                                                style={{width: '100%', height: '450px'}}
                                                frameBorder="0"
                                                src={`https://www.google.com/maps/embed/v1/place?key=AIzaSyDaMocA4Fuc9FbzvgcYuYwqfg_43q1U2zE&q=Pontificia+Universidad+Católica+del+Perú`}
                                                allowFullScreen/>
                                    </div>
                                    <RoomIcon style={{color: '#2bbbad'}}/>
                                    <h4>Artificial Intelligence Group</h4>
                                    <h4>Pontifical Catholic University of Peru</h4>
                                    <h4>Av. Universitaria 1801, San Miguel - Lima 32, Perú</h4>
                                    <h4>
                                        Website:{' '}
                                        <Link href="http://ia.inf.pucp.edu.pe/"
                                              target="blank_">
                                            http://ia.inf.pucp.edu.pe/
                                        </Link>
                                    </h4>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Container>
        </Layout>
    )
;

export default About;

About.propTypes = {
    center: PropTypes.object,
};
